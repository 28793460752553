import React from "react";

function Quiz(){
    return(
        <div className="container">
            퀴즈 페이지
        </div>
    )
}

export default Quiz;