import React from "react";

function Main(){
    return(
        <div className="container">
            메인 페이지
        </div>
    )
}

export default Main;